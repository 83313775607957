<template>
  <Layout class="pos-rel">
    <div class="banner"
         v-if="bannerBg"
         :style="`background-image:url(${bannerBg})`">
    </div>

    <div class="trans-box"
         :style="{marginTop:`${translateHeight}px`}">
      <div class="course rule-model-bg">
        <h1 class=" tx-c">公司历程</h1>
        <div class="cover rule-width">
          <div class="box">
            <div class="img"
                 ref="courseBox">
              <img src="@/assets/img/us2.png"
                   alt="photo">
            </div>
            <div class="shadow l"></div>
            <div class="shadow r"></div>
          </div>
        </div>
        <div class="scrollBar">
          <div class="box"
               :style="{ left: activewidth + 'px' }"
               @mousedown="move"></div>
        </div>
      </div>

      <div class="join rule-model">
        <h1 class="tx-c model-title">合作使我们更强大</h1>
        <h3 class="tx-c">享受钢琴演奏的乐趣</h3>
        <div class="rule-width flex flex-sb tx-c">
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/us3.png"
                   alt="">
            </div>
            <h3>合作钢琴</h3>
            <p>雅马哈集团</p>
          </div>
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/us4.png"
                   alt="">
            </div>
            <h3>合作机构</h3>
            <p>中国音乐学院社会艺术水平考级</p>
          </div>
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/us5.png"
                   alt="">
            </div>
            <h3>大赛授权</h3>
            <p>京都国际大赛日本组委会</p>
          </div>
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/us6.png"
                   alt="">
            </div>
            <h3>合作院校</h3>
            <p>上海音乐学院实验学校</p>
          </div>
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/us7.png"
                   alt="">
            </div>
            <h3>合作院校</h3>
            <p>石桥小学</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/index'
// import Class from './components/class.vue'
import animated from '@/utils/animated.js'
import bannerBg from '@/assets/img/us1.png'

export default {
  data() {
    return {
      bannerBg,
      activewidth: 0,
    }
  },
  mixins: [animated],
  components: {
    Layout,
    // Class,
  },
  methods: {
    move(ev) {
      //目标元素
      let odiv = ev.target
      // ScrollArea
      //算出鼠标相对元素的位置
      let disX = ev.clientX - odiv.offsetLeft
      //滚动条可以滚动的距离
      let viewArea = 800
      document.onmousemove = (e) => {
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX
        if (left < 0 || left > viewArea) {
          document.onmousemove = null
        } else {
          this.activewidth = left
          this.$refs.courseBox.style.left = left * -3 + 'px'
        }
      }
      document.onmouseup = () => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 1080px;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.trans-box {
  background: #fff;
  transition: 1.6s ease;
}
.course {
  .cover {
    margin-top: 60px;
    height: auto;
    position: relative;
    padding: 50px 0;
    .box {
      width: 100%;
      height: 400px;
      overflow-x: hidden;
      overflow-y: hidden;
      position: relative;
      .box::-webkit-scrollbar {
        display: none;
      }
      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 3600px;
        height: 400px;
        margin: 0 auto 20px;
        img {
          margin-left: 150px;
          width: 3300px;
          height: 100%;
          display: block;
        }
      }
    }
    .shadow {
      position: absolute;
      width: 150px;
      height: 100%;
      top: 0;
      &.l {
        left: 0;
        background: linear-gradient(to right, #f5f7f7, rgba(#f5f7f7, 0));
      }
      &.r {
        right: 0;
        background: linear-gradient(to left, #f5f7f7, rgba(#f5f7f7, 0));
      }
    }
  }
  .scrollBar {
    width: 900px;
    height: 2px;
    background: #000;
    margin: 0 auto;
    margin-top: 72px;
    border-radius: 4px;
    position: relative;
    .box {
      width: 100px;
      height: 12px;
      background: #000;
      border-radius: 8px;
      position: absolute;
      left: 0;
      z-index: 1;
      top: -5px;
    }
    .box:hover {
      cursor: pointer;
    }
  }
}
.join {
  .box {
    .img {
      height: 220px;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      font-size: 20px;
    }
  }
}
</style>
